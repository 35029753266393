
	//  -------------------------------------------------
	//  Bodyclasses

		if (is.desktop()) {
			$('body').addClass('is-desktop');
		};
		if (is.mobile()) {
			$('body').addClass('is-mobile');
		};
		if (is.tablet()) {
			$('body').addClass('is-tablet');
		};


	//  -------------------------------------------------
	//  TILES Utility


		$(".match-height").each(function(){
			// Get highest parent from element (of type .row)
			var highestParent = $(this).parents(".row").first(); 
			// Get height of reference parent
			var refHeight = highestParent.height();
			// Give determined height to element
			$(this).height(refHeight);
		});

	//  -------------------------------------------------
	//  MAP - Using provided address, or coords

	// Parameters
	var position_collection = new Array;
	var primary_position;
	var current_infoWindow;

	// Locate map center point using provided address

	function set_center_map(map, fitbound){
		// If position_collection have more than one position and set to fitbound

		if (position_collection.length > 0 && fitbound)
		{
			var bound = new google.maps.LatLngBounds()
			// Loop on all position

		 	for (var i = 0; i < position_collection.length; i++)
		 	{
		   		bound.extend(position_collection[i]);
			}

		  	map.fitBounds(bound);
		}
		else
		{
			map.setCenter(primary_position);
		}

	}

	function locate_using_coords(positionObject, map, markerBool, fitbound, multiple_markersBool){
	 	// If lat lng are provided
	 	lat_lng = { lat: positionObject.dataLat, lng: positionObject.dataLng};
	 	// Recenter map using coords
	 	// set_center_map(map,lat_lng);
	 	// Place a marker using coords
	 	if (markerBool == 1 && ((!multiple_markersBool && positionObject.primary) || (multiple_markersBool && !positionObject.primary))) {
		    var marker = new google.maps.Marker({
		        map: map,
		        position: lat_lng,
		        icon: positionObject.img,
		        content: positionObject.html
		    });

	      	  // If html is not empty fo a info window

	      	  if (positionObject.html != "")
	      	  {
				  var infowindow = new google.maps.InfoWindow({
					    content: positionObject.html
				  });

				  marker.addListener('click', function() {
				    if (current_infoWindow) {
				    	current_infoWindow.close();
				    }
				    current_infoWindow = infowindow;
				    infowindow.open(resultsMap, marker);
				  });
	      	  }
		}

		// Set the collection of marker or the primary position

		if (positionObject.primary)
		{
			primary_position = lat_lng;
		}
		else
		{
	    	position_collection.push(lat_lng);
		}

	    set_center_map(map,fitbound);
	} // locate_using_coords()


	function locate_using_address(positionObject,resultsMap,currentMapWrap, markerBool, fitbound, multiple_markersBool) {

		var geocoder = new google.maps.Geocoder();
	  	geocoder.geocode({'address': positionObject.address}, function(results, status) {

	    // If geocoding successfull
	    if (status === google.maps.GeocoderStatus.OK) {
	      // lat_lng = results[0].geometry.location;

	      // Place a marker using address
	      if ((markerBool == 1) && ((!multiple_markersBool && positionObject.primary) || (multiple_markersBool && !positionObject.primary))) {

	      	 var marker = new google.maps.Marker({
		        map: resultsMap,
		        position: results[0].geometry.location,
		        icon: positionObject.img,
		      });

	      	  // If html is not empty fo a info window

	      	  if (positionObject.html != "")
	      	  {
				  var infowindow = new google.maps.InfoWindow({
					    content: positionObject.html
				  });

				  marker.addListener('click', function() {
				    if (current_infoWindow) {
				    	current_infoWindow.close();
				    }
				    current_infoWindow = infowindow;
				    infowindow.open(resultsMap, marker);
				  });
	      	  }
		   }

		   	 // Set the collection of marker or the primary position

	     	 if (positionObject.primary)
	     	 {
				primary_position = results[0].geometry.location;
	     	 }
	     	 else
	     	 {
	      	 	position_collection.push(results[0].geometry.location);
	     	 }

	      	 set_center_map(resultsMap,fitbound);
	    } // geocoding successful

	    // If geocoding unsuccessful from provided address
	    // (ex: no address provided), Use provided lat-lng instead.
	    else {
	      	console.log(currentMapWrap.attr("class")+" -> Geocoding unsuccessful ("+positionObject.address+"); " + status);
	      	//currentMapWrap.hide();
	   		if(positionObject.dataLat != "" && positionObject.dataLng != "" && positionObject.dataLat != undefined && positionObject.dataLng != undefined){
		 		locate_using_coords(positionObject,resultsMap,markerBool,fitbound);
		 	}
		 	else{
		 		currentMapWrap.hide();
		 	}
	    } // If geocoding unsuccessful

	  });

	} // locate_using_address

	function initMap() {

		// Witness pointer
		var index = 0;

		var colors = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];


		// If at least one map on page

		if ($(".js-map").length) {

			// Browse each map info and create a map
		 	$(".js-map").each(function(){


		 		// Parameters

				position_collection = new Array;
				primary_position;

		 		var zoom = $(this).data("zoom");
		 		if (zoom == undefined || zoom == "") { zoom = 10; };

		 		var colored = $(this).data("colored");
		 		if (colored == undefined || colored == "" || colored == 0) { colored = 0; }
		 		else{ colored = colors;};

		 		var marker = $(this).data("marker");
		 		if (marker == undefined || marker == "") { marker = 0; };

		 		var ui = $(this).data("ui");
		 		if (ui == undefined || ui == "" || ui == 0) { ui = true; }
		 		else{ ui = false; }

		 		var fitbound = $(this).data("fitbound");
		 		if (fitbound == undefined || fitbound == "" || fitbound == 0) { fitbound = false; }
		 		else{ fitbound = true; }

		 		// If address provided :

		  		var address = $(this).data("addr");
		  		var dataLat = "";
			 	var dataLng = "";
			 	var html = $(this).html();
			 	var img = $(this).data("img");


			 	// Collection of marker html

			 	var html_marker_collection = new Array();

			 	// Bool for multiple markers

			 	var multiple_markers = 0;

			 	if ($(this).data("lat") != "" && $(this).data("lng")) {
			 		dataLat = $(this).data("lat");
			 		dataLng = $(this).data("lng");
			 	}

			 	//Generate position object with the primary position

			 	var primary_positionObject = {
			 		"primary":1,
			 		"address":address,
			 		"dataLat":dataLat,
			 		"dataLng":dataLng,
			 		"html":html,
			 		"img":img,
			 	};

			 	var li_count = $(this).find("li").length;

		 		// If the map have multiple markers, setup the collection of marker

		 		if (li_count)
		 		{
		 			if (!fitbound){
			 			html_marker_collection.push(primary_positionObject);
		 			}

		 			$(this).find("li").each(function() {

		 				html_marker_collection.push({
		 													"primary":0,
		 													"address":$(this).data("addr"),
		 													"dataLat":$(this).data("lat"),
		 													"dataLng":$(this).data("lng"),
	 														"html":$(this).html(),
		 													"img":$(this).data("img")
		 									   			});
		 			});
		 		}

		 		// Map declaration

		 		var map = new google.maps.Map($(".js-map")[index], {
				    zoom: zoom,
				    center: {lat: 0, lng: 0},
				    scrollwheel: false,
				    styles : colored,
				    disableDefaultUI: ui
				});

			 	// If is multiple markers

			 	if (html_marker_collection.length)
			 	{
			 		multiple_markers = true;

			 		// Loop on all markers

				 	for (var i = 0; i < html_marker_collection.length; i++)
				 	{
					  	// Initiate geocoding from map's data address, if provided
					 	if (html_marker_collection[i].address != "" && html_marker_collection[i].address != undefined) {
					 		locate_using_address(html_marker_collection[i],map ,$(this), marker, fitbound, multiple_markers);
					 	}
					 	// If not, use coordinates
					 	else if(html_marker_collection[i].dataLat != "" && html_marker_collection[i].dataLng != "" && html_marker_collection[i].dataLat != undefined && html_marker_collection[i].dataLng != undefined){
					 		locate_using_coords(html_marker_collection[i], map, marker, fitbound, multiple_markers);
					 	} else{
					 		console.log($(this).attr('class')+" -> "+$(this).index()+" -> No data involved.");
					 		$(this).hide();
					 	}
				 	}
			 	}
			 	else
			 	{
				  	// Initiate geocoding from map's data address, if provided
				 	if (address != "" && address != undefined) {
				 		locate_using_address(primary_positionObject, map, $(this), marker, fitbound, multiple_markers);
				 	}
				 	// If not, use coordinates
				 	else if(dataLat != "" && dataLng != "" && dataLat != undefined && dataLng != undefined){
				 		locate_using_coords(primary_positionObject, map, marker, fitbound, multiple_markers);
				 	} else{
				 		console.log($(this).attr('class')+" -> "+$(this).index()+" -> No data involved.");
				 		$(this).hide();
				 	}
			 	}

				// set_center_map(map, fitbound);

			 	index++;

		 	});

	 	} // If js-map contains something


 	} // initMap


	//  END MAP - Using provided address, or coords
	//  -------------------------------------------------

	//  -------------------------------------------------
	//  ACCORDIONs - Slide down - up

		var accordion = $(".Accordions");
		var accordion_length = accordion.length;

		if (accordion_length > 0) {

			var block = $(".Accordions_block");
			var header = $(".Accordions_header");
			var title = $(".Accordions_title");
			var content = $(".Accordions_content");
			var mainHeader = $('header');
			
			header.click(function(event){

				var self = $(this);

				event.preventDefault()

				if (!accordion.hasClass('Accordions-collapse')) {
					var accordions_active = $('.Accordions_block .Accordions_header.active');

					accordions_active.removeClass('active');

					if (accordions_active.length > 0) {
						accordions_active.next().slideUp(function(){
							if (self[0] != $(this).prev()[0]) {
								slideAccordion('toggle', self);
							}
						});
					}
					else {
						slideAccordion('toggle', self);
					}
				}
				else {
					slideAccordion('toggle', self);
				}
				var headerHeight = mainHeader.outerHeight();
				setTimeout(function() {
					$('html, body').animate({
	                    scrollTop: self.offset().top - headerHeight - 30
	                }, 250);
				}, 500);
			});



		};

		function slideAccordion(typeSlide,accordion) {
			switch(typeSlide.toLowerCase()) {
				case 'up':
			 		accordion.toggleClass("active");
					accordion.next().slideToggle(100);
			        break;
			    case 'down':
			 		accordion.toggleClass("active");
					accordion.next().slideToggle(100);
			        break;
			    case 'toggle':
			 		accordion.toggleClass("active");
					accordion.next().slideToggle(100);
			        break;
			}
		}

	//  -------------------------------------------------
	//  TOGGLE Utility

	$("[data-toggle]").click(function(){
		var target = $(this).data("toggle");
		$("[data-elem='"+target+"']").slideToggle();
	});

	$("menu nav .mobile-actions [data-toggle='menu']").click(function(){
		$(this).parents("menu").toggleClass("active");
	});


	//  -------------------------------------------------
	//  TABS Utility


	$('.nav-tabs a').click(function (e) {
	  e.preventDefault();
	  $(this).tab('show');
	});


	$(window).on('load', function(){
	/* ////////////////////////////////////////
	//
	// Init
	//
	/////////////////////////////////////// */

	//du code soit pour le loading mask ou pour initialiser le loading du site

		}).scroll(function(){
			scrollContent();
		});

		/* ////////////////////////////////////////
			//
			// Scroll content
			//
			/////////////////////////////////////// */
		function scrollContent(){
			var totalScroll = $(document).height() - $(window).height();

			//if(browserMobile){
			newScroll = $(window).scrollTop();
			// } else {
			// 	if(whichBrs() == 'Safari' || whichBrs() == 'Chrome'){
			// 		newScroll = $('body').scrollTop();
			// 	} else {
			// 		newScroll = $('html,body').scrollTop();
			// 	}
			// }

			currentScroll = newScroll;
			/* To-load
			================================================== */

			/* new system */

			if (is.desktop()) {
				$('.ToAnim').each(function(){
					var object = $(this);



					if(newScroll + $(window).height() * 1 > $(this).offset().top){
						object.addClass('IsAnim');
						object.removeClass('DoneAnim');

						// object.removeClass('NoAnim');
						// object.addClass('AnimDone');
					}
					else if(newScroll + $(window).height() < $(this).offset().top) {
						object.removeClass('IsAnim');
						object.addClass('DoneAnim');
						// object.addClass('NoAnim');
						// object.removeClass('AnimDone');
					}

				});
			} else {
				$('.ToAnim').addClass('IsAnim');
			}


			/* old system */
			// $('.to-load').each(function(){
			// 	var object = $(this);

			// 	if(newScroll + $(window).height() * 0.85 > $(this).offset().top){
			// 		object.removeClass('no-anim');
			// 		object.addClass('loaded');
			// 	} else if(newScroll + $(window).height() < $(this).offset().top) {
			// 		object.addClass('no-anim');
			// 		object.removeClass('loaded');
			// 	}

			// });

		}

		setTimeout(function(){
			scrollContent();
		}, 1000);



			// $('#background > div > img').each(function(){
			// 	var posLeft = ($(this).width() - $(this).parent().width()) / 2;
			// 	$(this).css({'left': - posLeft});
			// });

			scrollContent();


		$( document ).ready(function() {

			// scroll header
			$(window).on('scroll', function() {
		        var y_scroll_pos = window.pageYOffset;
		        var scroll_pos_test = 50;

		        if(y_scroll_pos > scroll_pos_test) {
		            $('header').addClass('scrolled');
		        }
		        if(y_scroll_pos < scroll_pos_test) {
		            $('header').removeClass('scrolled');
		        }
		    });

		    $('.slick-container').on('init', function(slick) {
				setTimeout(function () {

		    		$('.slick-container').animate({'opacity':1},1000);
				},1000);
			}).slick({
			    infinite: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				appendArrows: $('.slick-wrapper'),
				prevArrow: '<button type="button" class="slick-prev Btn Btn-yellow icon-c" data-icon="arrow_back"></button>',
				nextArrow: '<button type="button" class="slick-next Btn Btn-yellow icon-c" data-icon="arrow_forward"></button>',
				responsive: [
			    {
			      breakpoint: 1199,
			      settings: {
			        centerPadding: '34px',
			        slidesToShow: 3
			      }
			    },
			    {
			      breakpoint: 992,
			      settings: {
			        arrows: true,
			        centerPadding: '34px',
			        slidesToShow: 2
			      }
			    },
			    {
			      breakpoint: 768,
			      settings: {
			        arrows: true,
			        slidesToShow: 1
			      }
			    }
			  ]
			  });

		    $('.slick-single').on('init', function(slick) {
				setTimeout(function () {

					$('.slick-single').animate({'opacity':1},1000);
				},1000);
			}).slick({
		    	infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				arrows: false,
				dots: true,
				fade: true,
				appendDots: $('.slick-single')
			  });


		    $('.hamburger').on('click', function(){
		    	$(this).toggleClass('is-active');
		    	$('header').toggleClass('is-active');
		    	$('body').toggleClass('no-scroll');
		    	$('.subnav-content').removeClass('is-active');
		    	$('.mobile-nav').slideToggle();
		    });

		    $( '.desktop-nav .main-nav-wrapper ul li.sousmenu' ).find('>ul').wrap( '<div class="subnav-content"><div class="container"></div></div>' );
		    $( '.mobile-nav .main-nav-wrapper ul li.sousmenu' ).find('>ul').wrap( '<div class="subnav-content"></div>' );

		    if ($(window).width() < 769) {


		    	$('.sousmenu').find('> a').on('click', function(e){

			    	if ($(this).parent('.sousmenu').hasClass('is-active')) {
			    		e.preventDefault();
			    		$(this).parent('.sousmenu').removeClass('is-active');
				    	$(this).siblings('.subnav-content').slideUp();
			    	}
			    	else {
			    		e.preventDefault();
			    		$(this).parent('.sousmenu').addClass('is-active');
				    	$('.subnav-content').slideUp();
				    	$(this).siblings('.subnav-content').slideDown();
			    	}

			    })

		    };

		    if (is.tablet() || $(window).width() > 768) {
		    	$('.sousmenu > a').on('click', function(e){
		    		e.preventDefault();
		    	});
		    }

		    document.addEventListener('gesturestart', function (e) {
			    e.preventDefault();
			});

			$(function() {
				$('a[href*="#"]:not([href="#"])').click(function() {
					if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
							var target = $(this.hash);
							target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
							if (target.length) {
								$('html, body').animate({
									scrollTop: target.offset().top - 100
								}, 1000);
							return false;
						}
					}
				});
			});

		});

		$('form').submit(function (e) {
			var submit_button = $(this).find('button[type="submit"]');
			var span_loading_msg = submit_button.parent().find('span');

			submit_button.addClass('disabled').text(span_loading_msg.text());
		});


    $(function() {
        $('img.unveil').unveil(400);
    });




	/**
	 * Live feed
	 * */

	function initLiveFeed() {
		var wrapper = document.querySelector('#app-live')

		if(!wrapper) return false

		var app = new Vue({
			el: '#app-live',
			data: {
				request: {
					refreshDelay: 120,
					countdown: 0,
					raceID: 0,
					data: {},
				},
				detailedView: true
			},

			computed: {
				currentSession() {
					return this.request.data.Session ? this.request.data.Session : null
				},

				getDrivers() {
					return this.currentSession.Competitors ?  Object.keys(this.currentSession.Competitors).map(i => this.currentSession.Competitors[i]): null
				},

				getSorting() {
					return this.currentSession.SortMode
				},

				

				renderStortingType() {
					if(this.getSorting == 'race')
						return "Course // Race"
					
					return "Qualifications (Meilleur tour / Best Lap)"
				},

				getOrderedDrivers() {
					if(!this.getDrivers)
						return null

					var drivers = this.getDrivers

					if(this.getSorting == 'race')
						drivers.sort((a,b)=> (parseInt(a.Position) > parseInt(b.Position) ? 1 : -1))
					else
						drivers.sort((a,b) => this.timeToMs(a.BestLapTime) > this.timeToMs(b.BestLapTime) ? 1 : -1)
						drivers.sort( (a,b) => this.timeToMs(a.BestLapTime) == 0 ? drivers.length : 0 )

					return drivers
				}
			},

			methods: {
				async getData() {

					try {
						var formData = new FormData();
						formData.append('raceID', this.request.raceID);
			
						var response = await axios({
							method: 'post',
							url: '/getSession.php?raceid='+this.request.raceID,
							data: formData
						});
			
						if(response.status == 200 && response.data['Successful'] == true) {
							this.request.data = response.data
							this.request.countdown = this.request.refreshDelay
						} else {
							console.log(response)
						}
			
					} catch(error) {
						console.log(error)
					}
					

				},

				renderName(driver) {
					return `<strong>#${driver.Number}</strong> ${driver.FirstName} ${driver.LastName}`
				},

				renderDetails(driver) {
					if(!driver.Nationality && !driver.AdditionalData)
						return null

					return `${driver.Nationality}${driver.AdditionalData ? ' // ' + driver.AdditionalData : ''}`
				},

				renderClass(driver) {
					if (!driver.ClassID || Object.keys(this.currentSession.Classes).length === 0 )
						return null

					var str = null
					var array = Object.values(this.currentSession.Classes)
					
					array.forEach(obj => {
						if(obj.ClassID == driver.ClassID) {
							if(!str) {
								str = obj.Description
							}
							else {
								str = str + ' ' + obj.Description
							}
								
						}
					})

					return str

				
				},

				renderPosition(driver) {
					if(this.getSorting == 'race')
						return driver.Position
					
					return this.getOrderedDrivers.indexOf(driver) + 1
				},

				renderDiff(driver) {
					if(!this.getOrderedDrivers || !this.getOrderedDrivers[0] || this.getSorting == 'race')
						return null
					
					var firstDriver = this.getOrderedDrivers[0]

					if(firstDriver.RacerID == driver.RacerID)
						return null

					var firstTime = this.timeToMs(firstDriver.BestLapTime)
					var driverTime = this.timeToMs(driver.BestLapTime)

					var diff = (driverTime - firstTime) 

					return this.msToTime(diff)

				},

				

				timeToMs(str) {
					var hms = str.split('.')[0];
					// var ms = str.split('.')[1]
					var a = hms.split(':'); // split it at the colons

					// minutes are worth 60 seconds. Hours are worth 60 minutes.
					var ms = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]) * 1000;
					var ms = ms + parseInt(str.split('.')[1])

					return ms

				},

				stripHours(s) {
					if(!s) return null
					var i = s.indexOf(':');
					var splits = [s.slice(0,i), s.slice(i+1)];
										
					return splits[1] ? splits[1] : s;
				},

				msToTime(duration) {
					if(!duration) return 0

					var milliseconds = parseInt((duration % 1000)),
						seconds = Math.floor((duration / 1000) % 60),
						minutes = Math.floor((duration / (1000 * 60)) % 60),
						hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
					
					hours = (hours < 10) ? "0" + hours : hours;
					minutes = (minutes < 10) ? "0" + minutes : minutes;
					seconds = (seconds < 10) ? "0" + seconds : seconds;
					
					return minutes + ":" + seconds + "." + milliseconds;
				},
				
				getDriverClass(driver) {
					if(!driver) return null


				}
				  

			},

			mounted() {
				this.request.raceID = wrapper.dataset.raceid ? parseInt(wrapper.dataset.raceid) : 0
				this.request.refreshDelay = wrapper.dataset.delay ? parseInt(wrapper.dataset.delay) : 0

				this.getData();
			},

			watch: {
				'request.countdown': function (newVal, oldVal) {
					if (newVal > 0) {
						setTimeout(() => {
							this.request.countdown--;
						}, 1000);
					} else {
						this.getData()
					}
				}
			}

			
		})
			
	}

	document.addEventListener('DOMContentLoaded', () => {
		initLiveFeed();
	})







	/**
	 * Audio feed
	 */

	window.audiofeed = {
		element: null,
		liburl: 'https://cdnjs.cloudflare.com/ajax/libs/howler/2.2.3/howler.min.js',
		libLoaded: false,
		panelToggled: false,
		state: {
			state: 'stop',
			volume: 0.9,
			mute: false
		},
		howlerObject: null,

		/**
		 * Methods
		 */

		init: function() {

			this.element = document.querySelector('[data-audio="element"]')

			if(!this.element)
				return
			
			if(!this.libLoaded) {
				this.loadLib()
			} else {
				this.setListeners()
			}
		},

		loadLib: function() {
			var script = document.createElement('script');
			script.onload = () => {
				console.log('Howler loaded', Howl)
				this.libLoaded = true
				this.setListeners();
			};
			script.src = this.liburl;

			document.head.appendChild(script);
		},

		setListeners: function() {
			console.log('Setting listeners')
			
			this.element.querySelectorAll('[data-audio="play"]').forEach(button => {
				button.addEventListener('click', (el) => { this.start(button)} )
			} )
			
			this.element.querySelectorAll('[data-audio="stop"]').forEach(button => {
				button.addEventListener('click', (el) => { this.stop(button)} )
			} )

			this.element.querySelectorAll('[data-audio="volume"]').forEach(input => {
				input.addEventListener('input', (el) => { this.setVolume(input.value)} )
			} )

			this.element.querySelector('[data-audio="toggle"]').addEventListener('click', this.togglePlayer.bind(this) )

		},

		stop: function(el) {
			el.classList.add('hide')
			el.previousElementSibling.classList.remove('hide')
			el.nextElementSibling.querySelector('input').disabled = true

			if(!this.howlerObject)
				return false

			this.howlerObject.stop()
			this.howlerObject.unload()
			this.howlerObject = null
			this.state.state = "stop"
		},

		start: function(el) {
			

			this.element.querySelectorAll('[data-audio="stop"]').forEach(el => this.stop(el))

			el.classList.add('hide')
			el.nextElementSibling.classList.remove('hide')
			el.nextElementSibling.nextElementSibling.querySelector('input').disabled = false
			this.state.volume = el.nextElementSibling.nextElementSibling.querySelector('input').value

			this.howlerObject = new Howl({
				src: [el.dataset.audioSource],
				html5: true,
				volume: this.state.volume
			});
			this.howlerObject.on('loaderror',  (id, error) => {
				this.stop(el.nextElementSibling)
				alert(this.element.dataset.error)
				console.error(error)
			})
			this.howlerObject.play()
			this.state.state = "play"
		},

		setVolume: function(volume) {
			this.state.volume = volume

			if(!this.howlerObject)
				return false

			this.howlerObject.volume(volume)
		},

		togglePlayer() {
			if(this.panelToggled) {
				this.element.classList.remove('is-active')
				this.panelToggled = false
				return
			}

			this.element.classList.add('is-active')
			this.panelToggled = true
		}

	}

	document.addEventListener('DOMContentLoaded', () => {
		audiofeed.init()
	})

	

